import React, { useState } from 'react'
import './Footer.css'
import logo from '../../Images/Spl-logo.png'
import shade from '../../Images/btn-shade.png'
import { downloadApk } from '../../App'

const Footer = () => {

    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async () => {
        setIsLoading(true);
        try {
            await downloadApk();
        } finally {
            setIsLoading(false);
        }
    };

    return (

        <div className='footer'>

            <div className='container'>

                <div className='footer-content'>

                    <div className='footer-logo'>
                        <div className='company-logo'>

                            <div className='logo-img'><img src={logo} /></div>

                        </div>
                        <div className='logo-shadow'></div>
                    </div>

                    <div className='company-disclaimer'>

                        <div className='disclaimer-content'>

                            <h6>Viewing This WebSite Is On Your Own Risk. All The information Shown On Website Is Based on Numerology and Astrology for Information Purposes.
                                We Are Not Associated with Any Illegal Matka Business or Gamblers.We Warn You That Matka Gambling in Your Country May be Banned or Illegal.
                                We Are Not Responsible For Any Issues or Scam. We Respect All Country Rules/Laws. If You Not Agree With Our Site Disclaimer.
                                Please Quit Our Site Right Now. Copying/Promoting/Publishing Any of Our Content in Any Type Of Media or Other Source is Illegal and against Law.
                            </h6>

                        </div>

                        <div className='download-btn'>

                            <div className={`download-content ${isLoading ? "disable" : ""}`} onClick={handleDownload}>
                                <div className='shade'><img src={shade} /></div>
                                <div className='download-content-text'>
                                    {isLoading ?
                                        <div className="icon">
                                            <svg
                                                className="animate-spin"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    className="opacity-75"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            <h5>Please wait...</h5>
                                        </div>
                                        :
                                        <h5>download app</h5>}
                                </div>
                            </div>

                        </div>

                        <div className='logo-shadow'></div>

                    </div>

                </div>

                <hr />

                <div className='copyright'><h6>© Copyright 2021 spl.live all right reserved.</h6></div>

            </div>

        </div>
    )
}

export default Footer
