import React, { lazy, useEffect } from 'react';
import './App.css';
import { Routes, Route, useLocation, useParams } from 'react-router-dom';
import Navbar from './Components/01-Navbar/Navbar';
import Home from './Components/Home/Home';
import RateChart from './Components/04-RateChart/RateChart';
import MarketResult from './Components/06-Market-result/MarketResult';
// import StarlineChart from './Components/10-Starline-chart/StarlineChart';

import Footer from './Components/11-footer/Footer';
import MarketJodiChart from './Components/08-Market-jodi-chart/MarketJodiChart';
import MarketPanelChart from './Components/09-Market-panel-chart/MarketPanelChart';
import FloatingBtn from './Components/12-Floating-button/FloatingBtn';
import StarlineChart from './Components/10-Starline-chart/StarlineChart';
import anna from './Apk/splv2_0_3.apk'
import axios from 'axios';



// export const downloadApk = () => {
//   const timestamp = new Date().getTime(); // Unique value to prevent caching
//   const anchor = document.createElement('a');
//   anchor.href = `${anna}?v=${timestamp}`; // Cache-busting query parameter
//   anchor.download = "spl.apk";

//   document.body.appendChild(anchor);
//   anchor.click();
//   document.body.removeChild(anchor);

// }

export const downloadApk = async () => {
  try {
    const timestamp = new Date().getTime(); // Unique cache buster
    const fileUrl = `${process.env.REACT_APP_BASE_URL}/public/apk/spl_live.apk?v=${timestamp}`;

    const response = await axios.get(fileUrl, {
      responseType: 'blob', // Ensure we get binary data
      // headers: {
      //   'Cache-Control': 'no-cache, no-store, must-revalidate',
      //   'Pragma': 'no-cache',
      //   'Expires': '0'
      // }
    });

    const blob = new Blob([response.data], { type: 'application/vnd.android.package-archive' });
    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'spl_live.apk'; // APK file name
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    window.URL.revokeObjectURL(url); // Clean up the URL object

  } catch (err) {
    console.error("Download error:", err.message);
  }
};

function App() {

  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });


  }, [pathname]);


  return (
    <div className="App">

      <Navbar />

      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/rate-chart" element={<RateChart />} />
        <Route path="/market-results" element={<MarketResult />} />
        <Route path="/starline-chart" element={<StarlineChart />} />
        <Route path="/market-results/jodi-chart/:id" element={<MarketJodiChart />} />
        <Route path="/market-results/panel-chart/:id" element={<MarketPanelChart />} />

      </Routes>

      {
        pathname === '/rate-chart' || pathname.includes('/market-results') || pathname === '/starline-chart' ? <FloatingBtn /> : ''
      }

      <Footer />

    </div>
  );
}

export default App;
