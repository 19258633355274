import React, { useState } from 'react';
import './Header.css'
import anna from '../../Images/anna-vector.png'
// import anna from '../../Images/boy.png'
import shade from '../../Images/btn-shade.png'
import { downloadApk } from '../../App'

const Header = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async () => {
        setIsLoading(true);
        try {
            await downloadApk();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='header'>

            <div className='container'>

                <div className='header-content'>

                    <div className='header-text-content'>

                        <div className='sub-heading-1'><h4>Welcome to your</h4></div>
                        <div className='main-heading'><h1>Most Trusted App</h1></div>
                        <div className='sub-heading-1 mt-3'><h4>Play Instant Online Matka with SPL Live & Win Money Daily!</h4></div>

                        <div className='download-btn'>

                            <div className={`download-content ${isLoading ? "disable" : ""}`} onClick={handleDownload}>
                                <div className='shade'><img src={shade} /></div>
                                <div className='download-content-text'>
                                    {isLoading ?
                                        <div className="icon">
                                            <svg
                                                className="animate-spin"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    className="opacity-75"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            <h5>Please wait...</h5>
                                        </div>
                                        :
                                        <h5>download app</h5>}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='anna-vector'>

                        <img src={anna} />

                    </div>

                </div>

            </div>

        </div >
    )
}

export default Header
